import { createContext, useEffect, useState, lazy, Suspense } from 'react';
import './App.scss'
import { Routes, Route, Link, useNavigate, Outlet, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Container, Nav, Navbar, NavDropdown, Col, Row, Stack, Button } from 'react-bootstrap';
import ChatWidget from './components/chat/ChatWidget';

import { useSelector, useDispatch } from "react-redux";
import Test from './Test';
import axios from 'axios';
import { clearUser } from './store/loginUserSlice';
import { Insights, EdamBit } from './assets/svg';

const Trading = lazy(() => import('./pages/trading/Trading.jsx'));

function App() {
  const navigate = useNavigate();

  return (
    <div className='App'>
      <Suspense>
        <Routes>
          <Route path='/' element={<Trading />} />
        </Routes>
      </Suspense>
      {/* <ChatWidget /> */}
    </div>
  );
}

export default App;

const TopNavBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(state => state.loggedinUser);
  const conf = useSelector(state => state.config);

  const handleLogout = () => {
    axios.post(conf.url + "/logout")
      .then((result) => {
        dispatch(clearUser());
        navigate('/');
      })
      .catch((error) => {
        console.log('로그아웃 실패', error);
      })
  }

  return (
    <Navbar expand="lg">
      <Container className="d-flex justify-content-center">
        <div onClick={() => navigate('/')}>
          <Insights />
          <span className='mx-2'><EdamBit /></span>
        </div>
      </Container>
    </Navbar>
  )
}